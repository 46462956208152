@breakpointXl: 1220px;
@breakpointLg: 991px;
@breakpointSm: 767px;

@import "Style/breakpoints";
@import "less/parts/_mixins";

.b-landing {
  .h1 {
    display: flex;
    align-items: flex-start;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 20px;
    flex-direction: column;

    >:first-child {
      display: block;
      margin-bottom: 20px;
      width: 220px;
      height: 47px;
    }

    .b-landing__banner-registry .h1-label {
      border-radius: 5px;
      padding: 10px 15px;
      span:first-child {
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        margin-bottom: 5px;
      }
      span:last-child {
        font-size: 15px;
        line-height: 1;
        font-weight: 900;
        letter-spacing: 8%;
      }
    }
  }
  &__rates-table {
    display: block;
    margin-left: -20px;
    margin-right: -20px;

    @media screen and (min-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__some-landing {
    padding-bottom: 0;
    margin-bottom: 35px;

    .btn {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      &.btn-primary-outline {
        margin-top: 15px;
      }
    }

    .animation {
      display: none;
    }

    @media screen and (min-width: 540px) {
      .buttons {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;

        .btn {
          margin: 0 10px 10px;
          flex-grow: 0;
          width: auto;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .buttons {
        .btn {
          margin-bottom: 15px;
          min-width: 250px;
          padding-left: 30px;
          padding-right: 30px;
          white-space: nowrap;
        }
      }
    }

    @media screen and (min-width: 1220px) {
      .info {
        width: 50%;
        max-width: 50%;
      }
      .animation {
        display: block;
        width: 50%;
        max-width: 50%;
      }
    }
  }

  &__metrics {
    margin-bottom: 50px;
  }

  @media screen and (min-width: 480px) {
    &__some-landing {
      margin-bottom: 38px;
    }

    &__metrics {
      margin-bottom: 40px;
    }

    .h1 {
      display: flex;
      align-items: center;
      flex-direction: row;
      >:first-child {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__some-landing {
      margin-bottom: 64px;
    }

    &__metrics {
      margin-bottom: 70px;
    }

    .h1 {
      flex-direction: row;

      .b-landing__banner-logo {
        margin-right: 25px;
        margin-bottom: 0;
        display: block;
        width: 242px;
        height: 52px;
      }

      &-label {
        font-size: 13px;
      }
    }
  }

  @media screen and (min-width: 1220px) {
    &__some-landing {
      padding-bottom: 0;
      margin-bottom: 55px;
    }

    &__metrics {
      margin-bottom: 110px;
    }
  }
}

.contact-form {
  .validationMessage {
    display: none !important;
  }
}

@partnerWidth: {
  pochta: 140px;
  kia: 140px;
  z2z: 102px;
  elementaree: 154px;
  sibur: 184px;
  vtb: 170px;
  dns: 176px;
  kse: 182px;
  hatimaki: 194px;
  askona: 194px;
};

.partners {
  margin-left: -20px;
  margin-right: -20px;
  justify-content: space-between;

  .partner {
    display: block;
    width: 220px;
    flex-shrink: 0;
    margin: 20px 20px 0;
    text-decoration: none;

    &__logo {
      width: 100%;
      padding-bottom: 58%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        transition: opacity 250ms ease-in-out;

        &.colored {
          opacity: 0;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }

    &__name {
      margin-top: 15px;
      min-height: 2.4em;
      line-height: 1.2;
      font-size: 12px;
      text-align: center;
      color: black;
      opacity: 0;
      transition: opacity 250ms ease-in-out;
    }

    &:hover {
      .partner__logo {
        img {
          &.achromatic {
            opacity: 0;
          }
          &.colored {
            opacity: 1;
          }
        }
      }
      .partner__name {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 816px) {
    margin-left: -3px;
    margin-right: -3px;

    .partner {
      width: 33%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 3px;
      padding-right: 3px;
      margin-bottom: 15px;

      &__name {
        display: none;
      }
    }
  }
}
