.metrics-nps {
  display: flex;

  .chart {
    flex-shrink: 0;
    margin-right: 20px;
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value {
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 2px;

      &.positive {
        color: #00c968;
      }

      &.negative {
        color: #f96173;
      }

      &.neutral {
        color: #8ca3f7;
      }

      &:after {
        content: "%";
      }
    }

    .text {
      font-size: 18px;
      font-weight: 300;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .groups {
    margin-bottom: -20px;
  }

  .group {
    margin-bottom: 20px;

    .value {
      font-size: 26px;
      font-weight: 300;
      &:after {
        content: "%";
      }
    }
    .label {
      font-size: 13px;
    }

    &[data-group="promoters"] {
      .value {
        color: #00c968;
      }
    }
    &[data-group="neutrals"] {
      .value {
        color: #8ca3f7;
      }
    }
    &[data-group="critics"] {
      .value {
        color: #f96173;
      }
    }
  }

  @media screen and (min-width: 480px) {
    flex-direction: column;
    align-items: center;
    .chart {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .groups {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -6px -8px;
    }
    .group {
      margin: 6px 8px;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    .chart {
      width: 230px;
      height: 230px;
      margin-bottom: 26px;

      .value {
        font-size: 58px;
      }

      .text {
        font-size: 18px;
      }
    }
    .groups {
      margin: -10px -15px;
    }

    .group {
      margin: 10px 15px;
      .value {
        font-size: 40px;
      }
      .label {
        font-size: 15px;
      }
    }
  }

  @media screen and (min-width: 1220px) {
    flex-direction: row;

    .chart {
      margin-bottom: 0;
      margin-right: 40px;
      width: 280px;
      height: 280px;

      .value {
        font-size: 75px;
      }
      .text {
        font-size: 22px;
      }
    }

    .groups {
      flex-direction: column;
    }

    .group {
      text-align: left;

      .value {
        font-size: 40px;
        display: flex;
        align-items: center;

        &:before {
          content: "";
          display: inline-block;
          margin-left: 15px;
          width: 30px;
          height: 30px;
          order: 3;
        }
      }
      .label {
        font-size: 15px;
      }

      &[data-group="promoters"] {
        .value {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.4 17.8C9.4 17.8 11.5 20.6 15 20.6C18.5 20.6 20.6 17.8 20.6 17.8M10.8 10.8H10.814M19.2 10.8H19.214M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z' stroke='%2300C968' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &[data-group="neutrals"] {
        .value {
          &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.4 19.2H20.6M10.8 10.8H10.814M19.2 10.8H19.214M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z' stroke='%238CA3F7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &[data-group="critics"] {
        .value {
          &:before {
            content: "";

            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.6 20.6C20.6 20.6 18.5 17.8 15 17.8C11.5 17.8 9.4 20.6 9.4 20.6M10.8 10.8H10.814M19.2 10.8H19.214M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z' stroke='%23F96173' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }
}
